<template>
<v-autocomplete :items="countries" item-text="name" color="primary" v-model="selectedCountry" hide-selected filled label="Select a Country">
    <template v-slot:prepend-inner v-if="selectedCountry === ''">
        <v-icon>{{ mdiMapSearch }}</v-icon>
    </template>
    <template v-slot:append v-if="selectedCountry !== ''">
        <v-btn small icon color="error" @click="clearSearch()">
            <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
    </template>
    <template v-slot:item="data">
        <v-list-item-icon class="px-3 mr-0" v-if="data.item.id === ''">
            <v-icon large>{{ mdiFormatListBulleted }}</v-icon>
        </v-list-item-icon>
        <v-list-item-icon class="mr-0" v-else>
            <country-flag :country="data.item.id" size="normal" />
        </v-list-item-icon>
        <v-list-item-content @click="search({ type: 'country', term: data.item.id, name: data.item.name })">
            <v-list-item-title v-html="data.item.name" />
            <v-list-item-subtitle v-html="data.item.continent" />
        </v-list-item-content>
    </template>
    <template v-slot:selection="data">
        <v-icon class="mx-3" v-if="data.item.id === ''">{{ mdiFormatListBulleted }}</v-icon>
        <country-flag :country="data.item.id" size="normal" v-else />
        {{ data.item.name }}
    </template>
</v-autocomplete>
</template>

<script>
import { mdiMapSearch, mdiFormatListBulleted, mdiClose } from "@mdi/js";
import { mapGetters } from "vuex";

export default {
    name: "CountrySelect",

    components: {
        CountryFlag: () => import("vue-country-flag").then(m => m.default)
    },

    data() {
        return {
            mdiClose: mdiClose,
            mdiMapSearch: mdiMapSearch,
            mdiFormatListBulleted: mdiFormatListBulleted,
            selectedCountry: "",
        };
    },

    computed: {
        ...mapGetters(["countries"]),
    },

    mounted() { },

    methods: {
        search(query) {
            this.$emit('search', true, query);
        },
        clearSearch() {
            this.selectedCountry = "";
            this.search({ type: "", term: "", name: "" })
        }
    },
};
</script>

<style lang="scss" scoped>
.v-select-list {
    background-color: #EEEEEE !important;
}
</style>